import React from 'react'

import RedeSeguros from '../../../../../../assets/svgs/RedeSeguros'

import Button from 'components/lib/Button'
import Header from 'components/lib/Header'

// eslint-disable-next-line no-undef
const RedeCorretoraUrl = APP_REDE_CORRETORA_URL

const AccountInformation = ({ segurosAccount, onCreateAnalysis }) => {
  const token = localStorage.getItem('izee-token')
  
  return (
    <>
      <Header
        isTiny
        isUppercase
        subtitle={<hr style={{ margin: '8px 0px 16px 0px' }} />}
      >
        <RedeSeguros />
      </Header>
      {segurosAccount.has_account ? (
        <>
          {segurosAccount.can_quote ? (
            <Button
              style={{ marginBottom: 8 }}
              hasThemeColor
              onClick={onCreateAnalysis}
            >
              Solicitar análise
            </Button>
          ) : (
            <span>
              Você não tem permissão para criar análises, solicite o cadastro de seu
              usuário no painel da corretora.
            </span>
          )}
        </>
      ) : (
        <>
          <span>A imobiliária ainda não possui conta na Corretora, </span>
          <a
            href={`${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=`}
          >
            clique aqui para solicitar.
          </a>
        </>
      )}
    </>
  )
}

export default AccountInformation
