import request from 'axios'

const withToken = async (url, body) => {
  try {
    return new Promise((resolve, reject) => {
      request
        .post(url, body)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          console.log('error: ', error)
          reject(error)
        })
    })
  } catch (error) {
    throw new Error('withToken error: ' + error)
  }
}

const url = CONFIAX_INTEGRATIONS_API_URL

const integrationsSeguros = {
  seguros: async (body) => {
    try {
      const response = await withToken(`${url}`, body)

      return response
    } catch (error) {
      throw new Error(error)
    }
  },

  checkAccount: async (companyId, company) => {
    const cachedData = sessionStorage.getItem('seguros_account_data')

    if (cachedData) {
      return { success: true, data: JSON.parse(cachedData) }
    }

    const { success, data } = await integrationsSeguros.seguros({
      companyId,
      payload: {
        action: 'CHECK_ACCOUNT',
        company,
      },
    })

    if (
      success &&
      data.app?.response?.data?.has_account &&
      data.app?.response?.data?.can_quote
    ) {
      sessionStorage.setItem('seguros_account_data', JSON.stringify(data))
    }

    return { success, data }
  },
}

export default integrationsSeguros
