import Surveys from './containers/Surveys'
import Analysis from './containers/Analysis'
import FireInsurance from './containers/FireInsurance'

const routes = [
  {
    name: 'Surveys',
    path: '/app/:userId/:companyId/services/surveys',
    component: Surveys,
  },
  {
    name: 'Analysis',
    path: '/app/:userId/:companyId/services/analysis',
    component: Analysis,
  },
  {
    name: 'Fire Insurance',
    path: '/app/:userId/:companyId/services/fire-insurance',
    component: FireInsurance,
  },
]

export default routes
