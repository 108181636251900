import React, { useState } from 'react'

import RedeSeguros from '../../../../../../assets/svgs/RedeSeguros'

import Header from 'components/lib/Header'
import Button from 'components/lib/Button'
import moment from 'moment'

import {
  TableContainer,
  HeadContainer,
  RowContainer,
  StyledButton,
  Dimmer,
} from './styles'
import integrationsSeguros from '../../../..'
import Loading from '../Loading'

// eslint-disable-next-line no-undef
const RedeCorretoraUrl = APP_REDE_CORRETORA_URL

const Contratar = (token, bondInsuranceId, bondInsuranceanalysisId) => {
  window.open(
    `${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=&bondInsuranceId=${bondInsuranceId}&bondInsuranceanalysisId=${bondInsuranceanalysisId}`,
    '_blank',
  )
}

const Analysis = ({ quote, downloadPolicy, notification }) => {
  const [isLoading, setIsLoading] = useState(false)
  const token = localStorage.getItem('izee-token')

  const sendLetter = async (analysisId) => {
    try {
      setIsLoading(true)
      const { success } = await integrationsSeguros.seguros({
        companyId: '_',
        payload: {
          action: 'SEND_LETTER',
          analysisId,
        },
      })

      if (success) {
        notification({
          message: 'Carta enviada com sucesso',
        })
      }
    } catch (error) {
      notification({
        message: 'Erro ao enviar carta',
        status: 'danger',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading && (
        <Dimmer>
          <Loading isLoading={isLoading} />
        </Dimmer>
      )}
      <div style={{ marginBottom: '10px' }}>
        <RedeSeguros />
      </div>
      <Header
        isTiny
        isUppercase
        subtitle={<hr style={{ margin: '4px 0px 16px 0px' }} />}
      >
        <div>Cotações</div>
      </Header>

      <TableContainer>
        <HeadContainer>
          <span>Nome</span>
          <span>Valor</span>
          <span>Status</span>
          <span>Expira em</span>
          <span>Ações</span>
        </HeadContainer>
        {quote.bond_insurance_analysis.map((analyse, i) => (
          <RowContainer key={i}>
            <span>{analyse.insurer.name}</span>
            <span>
              {analyse.status == 'Aprovado' ||
              analyse.status == 'Aguardando Biometria'
                ? analyse.insurer.code == 'GARANTTI'
                  ? analyse.insurer_response
                    ? analyse.insurer_response.map((plan, i) =>
                        quote.purpose == 'RESIDENTIAL' ? (
                          <span key={i}>
                            {`${plan.description}: ${
                              plan.rent_months_count
                            } x R$ ${Number(
                              plan.residentialfee_assurance_month_amount,
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} `}
                            <br />
                          </span>
                        ) : (
                          <span key={i}>
                            {`${plan.description}: ${
                              plan.rent_months_count
                            } x R$ ${Number(
                              plan.commercialfee_assurance_month_amount,
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })} `}
                            <br />
                          </span>
                        ),
                      )
                    : analyse.value
                    ? `${quote.property.validity} x R$ ${Number(
                        analyse.value,
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : ''
                  : analyse.insurer.code == 'PORTO_ESSENCIAL'
                  ? analyse.insurer_response
                    ? analyse.insurer_response.map((plan, i) => (
                        <span key={i}>
                          {`${plan.nome_seguro}: ${
                            plan.quantidade_parcela
                          } x R$ ${Number(plan.valor_parcela).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          )} `}
                          <br />
                        </span>
                      ))
                    : analyse.value
                    ? `${quote.property.validity} x R$ ${Number(
                        analyse.value,
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : ''
                  : analyse.value
                  ? `${quote.property.validity} x R$ ${Number(
                      analyse.value,
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : ''
                : ''}
            </span>
            <span>{analyse.status}</span>
            <span>{moment(analyse.expiration_date).format('DD/MM/YYYY')}</span>
            <span>
              {analyse.insurer_proposal_id ? (
                analyse?.bond_insurance_hired_data?.insurer_policy_id ? (
                  <Button
                    isFlat
                    hasThemeColor
                    onClick={() => downloadPolicy(analyse.bond_insurance_id)}
                  >
                    Baixar apólice
                  </Button>
                ) : (
                  <Button isDisabled isFlat hasThemeColor>
                    Contratação solicitada
                  </Button>
                )
              ) : (
                analyse.status === 'Aprovado' &&
                !quote.bond_insurance_analysis.some(
                  (el) => el.insurer_proposal_id,
                ) && (
                  <StyledButton type='hire'>
                    <Button
                      isFlat
                      onClick={() => Contratar(token, quote.id, analyse.id)}
                    >
                      Contratar
                    </Button>
                  </StyledButton>
                )
              )}

              {analyse.insurer.code === 'POTTENCIAL' &&
                analyse.status === 'Aguardando Biometria' && (
                  <StyledButton type='biometry'>
                    <Button
                      isFlat
                      onClick={() => {
                        window.open(
                          'https://paravoce.pottencial.com.br/biometria-facil',
                          '_blank',
                        )
                      }}
                    >
                      Cadastrar Biometria
                    </Button>
                  </StyledButton>
                )}

              {analyse.status === 'Pré-aprovado' && (
                <StyledButton
                  type='pre-approved'
                  onClick={() => sendLetter(analyse.id)}
                >
                  <Button isFlat disabled={isLoading}>
                    Enviar carta pré-aprovado
                  </Button>
                </StyledButton>
              )}
            </span>
          </RowContainer>
        ))}
      </TableContainer>
      {quote.bond_insurance_analysis.some((el) => el.insurer_proposal_id) &&
        !quote.bond_insurance_analysis.some(
          (el) => el?.bond_insurance_hired_data?.insurer_policy_id,
        ) && (
          <div style={{ marginTop: '10px' }}>
            <span>
              Após solicitar a contratação, acesse o painel da Rede Seguros para
              continuar o processo. &nbsp;
            </span>
            <a
              href={`${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=`}
              target='_blank'
              rel='noreferrer'
            >
              Acessar o painel.
            </a>
          </div>
        )}
    </>
  )
}

export default Analysis
