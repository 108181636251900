import React, { useEffect, useState } from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { hot } from 'react-hot-loader'

import { ChildRoutes } from 'utils/RouterUtils'
import baseRoutes from 'core/baseRoutes'
import store from 'core/store'
import rollbar from '../services/tracking/rollbar'

import PopupImage from '../assets/popup.png'

const App = (props) => {
  const [isModalVisible, setModalVisible] = useState(false) // Controls modal visibility

  rollbar.init()
  const basename = document.querySelector('base')
    ? document.querySelector('base').getAttribute('href')
    : null

  useEffect(() => {
    const today = new Date()
    const cutoffDate = new Date('2025-04-13')
    const hasSeenModal = localStorage.getItem('hasSeenFireInsuranceModal')

    // Show modal if it's before the cutoff date and the user hasn't seen it
    if (today <= cutoffDate && !hasSeenModal) {
      setModalVisible(true)
    }
  }, [])

  // Close modal function
  const closeModal = () => {
    setModalVisible(false)
    localStorage.setItem('hasSeenFireInsuranceModal', 'true')
  }

  const handleOutsideClick = (e) => {
    if (e.target.id === 'modal-overlay') {
      closeModal()
    }
  }

  return (
    <Provider store={store}>
      {isModalVisible && (
        <div
          id='modal-overlay'
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
          }}
          onClick={handleOutsideClick}
        >
          <div
            style={{
              background: '#fff',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              position: 'relative',
            }}
          >
            <img
              src={PopupImage}
              alt='Modal Content'
              style={{ maxWidth: '100%', maxHeight: '80vh', display: 'block' }}
            />
            <button
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                background: 'transparent',
                border: 'none',
                fontSize: '16px',
                cursor: 'pointer',
              }}
            >
              ✕
            </button>
          </div>
        </div>
      )}
      <Router basename={basename || ''}>
        <ChildRoutes {...props} routes={baseRoutes} />
      </Router>
    </Provider>
  )
}

let exportedApp = App

// Use hot loader only in development
if (process.env.NODE_ENV !== 'production') exportedApp = hot(module)(App)

export default exportedApp
