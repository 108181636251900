import React from 'react'
import FireInsurance from '../../../../services/integrations-fire-insurance'

const FireInsuranceContainer = (props) => {
  return (
    <FireInsurance notification={props.notification} company={props.activeCompany} />
  )
}

export default FireInsuranceContainer
