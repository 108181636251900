import React from 'react'
import styled from 'styled-components'
import Button from 'components/lib/Button'
import Icon from 'components/lib/Icon'

// eslint-disable-next-line no-undef
const RedeCorretoraUrl = APP_REDE_CORRETORA_URL

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`

const Title = styled.h2`
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
`

const Separator = styled.hr`
  border: none;
  border-top: 1px solid #ddd;
  margin: 16px 0;
`

const InfoText = styled.span`
  font-size: 1rem;
  color: #555;
  margin-bottom: 8px;
`

const AccountInformation = ({ segurosAccount, onCreateAnalysis }) => {
  const token = localStorage.getItem('izee-token')

  return (
    <>
      <Header>
        <div>
          <Title>Cotação de Seguro Incêndio</Title>
          <InfoText>
            Simule e contrate uma apólice utilizando a plataforma da RV Seg.
          </InfoText>
        </div>
        {segurosAccount.has_account && segurosAccount.can_quote && (
          <Button hasThemeColor onClick={onCreateAnalysis}>
            <Icon name='fas fa-clipboard' /> Nova cotação
          </Button>
        )}
      </Header>

      <Separator />

      {segurosAccount.has_account ? (
        segurosAccount.can_quote ? (
          <></>
        ) : (
          <InfoText>
            Você não tem permissão para criar análises. Solicite o cadastro de seu
            usuário no painel da corretora.
          </InfoText>
        )
      ) : (
        <InfoText>
          A imobiliária ainda não possui conta na Corretora,&nbsp;
          <a
            href={`${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=`}
          >
            clique aqui para solicitar.
          </a>
        </InfoText>
      )}
    </>
  )
}

export default AccountInformation
