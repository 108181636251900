import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { request } from 'core/constants'
import { request as insuranceApi } from './external-api'
import styled from 'styled-components'

import Button from 'components/lib/Button'
import Pagination from 'components/lib/Pagination'
import integrationsSeguros from '../integrations-seguros'
import AccountInformation from './AccountInformation'
import {
  TableContainer,
  HeadContainer,
  RowContainer,
  StyledButton,
} from '../integrations-seguros/components/Seguros/components/Analysis/styles'
import moment from 'moment'
import QuoteModal from './QuoteModal'

const LoadingContainer = styled.div`
  height: 250px;
  margin-top: 100px;
`

const Spinner = styled.div`
  margin: 0 auto;
  padding: 45px 60px;
`

// eslint-disable-next-line no-undef
const RedeCorretoraUrl = APP_REDE_CORRETORA_URL

const Contratar = (token, fireInsuranceId) => {
  window.open(
    `${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=&stage=analysis&fireInsuranceId=${fireInsuranceId}`,
    '_blank',
  )
}

const Acessar = (token, fireInsuranceId) => {
  window.open(
    `${RedeCorretoraUrl}#access_token=${token}&token_type=Bearer&expires_in=&stage=policy&fireInsuranceId=${fireInsuranceId}`,
    '_blank',
  )
}

const FireInsurance = ({ company, proposal, notification }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [resultsInfo, setResultsInfo] = useState({})
  const [filter, setFilter] = useState({ limit: 5 })
  const token = localStorage.getItem('izee-token')

  const [segurosAccount, setSegurosAccount] = useState({})
  const [items, setItems] = useState([])

  const saveNewFireInsurance = async (externalId) => {
    try {
      await request.post(
        proposal?.id
          ? `/company/${company.id}/proposal/${proposal.id}/fire-insurance`
          : `/company/${company.id}/fire-insurance`,
        {
          external_id: externalId,
        },
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error saving fire insurance:', error)
      notification({
        message: 'Erro ao salvar seguro incêndio',
        status: 'danger',
      })
    } finally {
      setModalIsOpen(false)
      fetchQuotes()
    }
  }

  const fetchQuotes = async () => {
    try {
      const query = new URLSearchParams(filter).toString()
      const { data, resultsInfo } = await request.get(
        (proposal?.id
          ? `/company/${company.id}/proposal/${proposal.id}/fire-insurance`
          : `/company/${company.id}/fire-insurance`) + (query ? `?${query}` : ''),
      )

      const quotes = await Promise.all(
        data.map(async (item) => {
          const { data } = await insuranceApi.get(
            `/fire-insurance-quotations/quotes?fireInsuranceId=${item.external_id}`,
          )

          if (data && data[0].length > 0 && data[0][0]) {
            return data[0][0]
          }

          const fallbackResponse = await insuranceApi.get(
            `fire-insurance-proposals?fireInsuranceId=${item.external_id}`,
          )

          const proposal = fallbackResponse.data[0][0]

          return {status: proposal.status, createdAt: proposal.createdAt, ...proposal.fireInsuranceAnalysis}
        }),
      )

      setItems(quotes)

      setResultsInfo(resultsInfo)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching quotes:', error)
      notification({
        message: 'Erro ao buscar cotações de seguro incêndio',
        status: 'danger',
      })
    }
  }

  useEffect(() => {
    fetchQuotes()
  }, [filter])

  const fetchAccount = async () => {
    try {
      setIsLoading(true)
      const { success, data } = await integrationsSeguros.checkAccount(
        company.id,
        company,
      )

      if (success) {
        setSegurosAccount(data.app.response.data)
      }
    } catch (e) {
      notification({
        message: 'Erro ao checar sua conta na Rede Seguros',
        status: 'danger',
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchAccount()
  }, [])

  const handlePagination = (page) => {
    setFilter({ page: page, limit: 5 })
  }

  const translateStatus = (status) => {
    switch (status) {
      case 'POLICY_CREATED':
        return 'Apólice vigente'
      case 'PROPOSAL_WAITING':
        return 'Em Espera'
      case 'POLICY_CREATING':
        return 'Aguardando Apólice'
      case 'CANCELATION_REQUEST':
        return 'Cancelamento Solicitado'
      case 'POLICY_AWAITING_BILLING':
      case 'WAITING_INVOICE':
      case 'WAITING_PAYMENT':
        return 'Apólice a faturar'
      case 'CANCELED':
        return 'Apólice Cancelada'
      case 'POLICY_EXPIRED':
        return 'Apólice expirada'
      case 'POLICY_ACTIVE':
        return 'Apólice vigente'
      case 'ERROR':
        return 'Erro'
      case 'IN_PROPOSAL':
        return 'Em Proposta'
      case 'EXPIRED':
        return 'Expirado'
      case 'WAITING':
        return 'Em espera'
      case 'CREATING':
        return 'Em espera'
      case 'UNDERANALYSIS':
        return 'Em análise'
      case 'DENIED':
        return 'Recusado'
      case 'APPROVED':
        return 'Aguardando Contratação'
      case '':
        return '(Indisponível)'

      /// status da segimob!
      case '12':
        return 'Aguardando Faturamento'
      case '10':
        return 'Aguardando Pagamento'
      case '19':
        return 'Apólice Expirada'
      case '3':
        return 'Cancelada'
      case '20':
        return 'Cancelada Inadimplencia'
      case '4':
        return 'Cancelamento Manual'
      case '8':
        return 'Cancelando'
      case '1':
        return 'Em proposta'
      case '2':
        return 'Emitida'
      case '18':
        return 'Emitindo Proposta'
      case '14':
        return 'Excluída'
      case '9':
        return 'Pago'
      default:
        return status
    }
  }

  return (
    <>
      {isLoading ? (
        <>
          <p className='text-center'>Carregando...</p>
          <div>
            <LoadingContainer>
              <Spinner className='empty-state-spinner' />
            </LoadingContainer>
          </div>
        </>
      ) : (
        <AccountInformation
          segurosAccount={segurosAccount}
          onCreateAnalysis={() => {
            setModalIsOpen(true)
          }}
        />
      )}
      {items && items.length > 0 && (
        <>
          <TableContainer>
            <HeadContainer>
              <span>Data da cotação</span>
              <span>Seguradora</span>
              <span>Valor</span>
              <span>Status</span>
              <span>Ações</span>
            </HeadContainer>
            {items.map((i) => (
              <RowContainer key={i.fireInsuranceId}>
                <span>{moment(i.createdAt).format('DD/MM/YYYY')}</span>
                <span>{i.insurer.name}</span>
                <span>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(
                    i.value *
                      i.installments,
                  )}{' '}
                  {i.installments > 1
                    ? `(${
                        i.installments
                      } x ${new Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL',
                      }).format(i.value)})`
                    : ''}
                </span>
                <span>{translateStatus(i.status)}</span>
                <span>
                  {i.status === 'APPROVED' || i.status === '1' ? (
                    <StyledButton type='hire'>
                      <Button isFlat onClick={() => Contratar(token, i.fireInsuranceId)}>
                        Contratar
                      </Button>
                    </StyledButton>
                  ) : (
                    <StyledButton>
                      <Button isFlat onClick={() => Acessar(token, i.fireInsuranceId)}>
                        Acessar
                      </Button>
                    </StyledButton>
                  )}
                </span>
              </RowContainer>
            ))}
          </TableContainer>
          <div className='text-center mt-5 mb-5'>
            <Pagination
              current={resultsInfo.currentPage}
              total={resultsInfo.total}
              itemsPerPage={resultsInfo.perPage}
              onChange={handlePagination}
            />
          </div>
        </>
      )}
      <QuoteModal
        isOpen={modalIsOpen}
        onClose={() => setModalIsOpen(false)}
        proposal={proposal}
        onFireInsuranceCreated={saveNewFireInsurance}
      />
    </>
  )
}

FireInsurance.propTypes = {
  company: PropTypes.object.isRequired,
  proposal: PropTypes.object,
  notification: PropTypes.func.isRequired,
}

export default FireInsurance
