import React, { useEffect } from 'react'
import Modal from 'components/lib/Modal'

// eslint-disable-next-line no-undef
const RedeCorretoraUrl = APP_REDE_CORRETORA_URL.replace('/auth-validate', '')

const QuoteModal = ({ isOpen, onClose, proposal, onFireInsuranceCreated }) => {
  let queryParams = new URLSearchParams()

  queryParams.set('token', localStorage.getItem('izee-token'))

  if (proposal?.rent_value) {
    queryParams.set('rentValue', proposal.rent_value)
  }
  if (proposal?.ownership_date) {
    const [year, month, day] = proposal.ownership_date.split('-')

    queryParams.set('startDate', `${month}-${day}-${year}`)
  }

  if (proposal?.properties?.[0]?.address) {
    const { address, number, additional_address, neighborhood, city, state, zip_code } = proposal.properties[0].address

    if (address) queryParams.set('street', address)
    if (number) queryParams.set('number', number)
    if (additional_address) queryParams.set('complement', additional_address)
    if (neighborhood) queryParams.set('neighborhood', neighborhood)
    if (city) queryParams.set('city', city)
    if (state) queryParams.set('state', state)
    if (zip_code) queryParams.set('zipCode', zip_code)
  }

  if (proposal?.clients?.[0]) {
    const { name, cpf, cnpj, birth_date, email, phone } = proposal.clients[0]

    if (name) queryParams.set('tenantName', name)
    if (cpf) queryParams.set('tenantDocument', cpf)
    if (cnpj) queryParams.set('tenantDocument', cnpj)
    if (birth_date) queryParams.set('tenantBirthDate', birth_date)
    if (email) queryParams.set('tenantEmail', email)
    if (phone) queryParams.set('tenantPhone', phone)
  }

  if (proposal?.property_owners?.[0]) {
    const { name, cpf, cnpj, email } = proposal.property_owners[0]

    if (name) queryParams.set('insuredName', name)
    if (cpf) queryParams.set('insuredDocument', cpf)
    if (cnpj) queryParams.set('insuredDocument', cnpj)
    if (email) queryParams.set('insuredEmail', email)
  }

  let iFrameUrl = `${RedeCorretoraUrl}/iframe/fire-insurance-budget?${queryParams.toString()}`

  const configureMessageListener = () => {
    window.addEventListener('message', (event) => {
      const { type, data } = event.data

      if (type === 'BUDGET_CREATED' && data.fireInsuranceId) {
        onFireInsuranceCreated(data.fireInsuranceId)
      }
    })
  }

  useEffect(() => {
    configureMessageListener()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      responsive={true}
      bodyStyle={{
        maxWidth: '100%',
      }}
      isWide={true}
      title='Cotação de Seguro Incêndio'
    >
      {isOpen && (
        <iframe src={iFrameUrl} width='100%' height='800px' frameBorder='0' />
      )}
    </Modal>
  )
}

export default QuoteModal
