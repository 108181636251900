import axios from 'axios'

// eslint-disable-next-line no-undef
export const baseURL = API_REDE_CORRETORA_URL

export const request = axios.create({
  baseURL: baseURL,
})

request.interceptors.request.use((config) => {
  const token = localStorage.getItem('izee-token')

  if (token) {
    config.headers.Authorization = token
  }

  return config
})
